import React from "react";
import { Layout, Menu } from "antd";
import {
  SettingOutlined,
  TeamOutlined,
  DollarCircleOutlined,
  MacCommandOutlined,
  GoldOutlined,
  BugOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const { Sider } = Layout;

const SiderBar = ({ toggle, collapsed }) => {
  const navigate = useNavigate();
  const { userData } = useSelector((state) => {
    return state?.app;
  });

  const is_staff = userData?.is_staff;
  const is_client = userData?.is_client;
  const is_superuser = userData?.is_superuser;

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={toggle}>
      <div className="logo" />
      <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
        <Menu.Item
          icon={<GoldOutlined />}
          onClick={() => navigate("/projects")}
          key="1"
        >
          Projects
        </Menu.Item>
        {/* {is_superuser && (
          <Menu.Item
            icon={<BugOutlined />}
            onClick={() => navigate("/all-bugs")}
            key="2"
          >
            All Bugs
          </Menu.Item>
        )} */}
        <Menu.Item
          icon={<BugOutlined />}
          onClick={() => navigate("/bugs")}
          key="3"
        >
          Bugs
        </Menu.Item>
        {is_superuser && (
          <Menu.Item
            icon={<DollarCircleOutlined />}
            onClick={() => navigate("/subscriptions")}
            key="4"
          >
            Subscriptions
          </Menu.Item>
        )}
        {(is_superuser || is_client) && (
          <Menu.Item
            icon={<MacCommandOutlined />}
            onClick={() => navigate("/packages")}
            key="5"
          >
            Packages
          </Menu.Item>
        )}
        {is_superuser && (
          <Menu.Item
            icon={<TeamOutlined />}
            onClick={() => navigate("/clients")}
            key="6"
          >
            Clients
          </Menu.Item>
        )}
        {is_superuser && (
          <Menu.Item
            icon={<TeamOutlined />}
            onClick={() => navigate("/staff")}
            key="7"
          >
            Staff
          </Menu.Item>
        )}
        {is_superuser && (
          <Menu.Item
            icon={<DollarOutlined />}
            onClick={() => navigate("/transactions")}
            key="8"
          >
            Transactions
          </Menu.Item>
        )}
        {is_superuser && (
          <Menu.Item
            icon={<SettingOutlined />}
            onClick={() => navigate("/settings")}
            key="9"
          >
            Global Settings
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  );
};

export default SiderBar;
