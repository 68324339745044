import { message, Tag } from "antd";

export const uaePhoneRegex =
  /^(?:\+971|00971|0)(?:2|3|4|6|7|9|50|51|52|55|56)[0-9]{7}$/;
export const isNumberRegex = /^\d+$/;

export const getDataManager = async (dataApi, setLoading, payload, id) => {
  try {
    setLoading && setLoading(true);
    return dataApi(payload, id)
      .then((res) => {
        setLoading && setLoading(false);
        if (res) {
          return res;
        }
      })
      .catch((error) => {
        setLoading && setLoading(false);
        message.error({
          content: error || "Something went wrong",
          duration: 3,
        });
      });
  } catch (error) {
    setLoading && setLoading(false);
    message.error({ content: error || "Something went wrong", duration: 3 });
  }
};

export const extractContent = (html) => {
  var temporalDivElement = document.createElement("div");
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
};

export const getDateString = () => {
  const d = new Date();
  const dateString = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  return dateString;
};

export const getUnitVatAmount = (price, vat) => {
  const p = Number(price);
  const v = Number(vat);
  const pt = p * (v / 100);
  return pt.toFixed(2);
};

export const validateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const validateNumber = (number) => {
  const regex = /^\(?0( *\d\)?){9,10}$/;
  return regex.test(String(number));
};
export const validatePassword = (pass) => {
  const regex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  return regex.test(String(pass));
};

export const toCapitalize = (str = "") => {
  const value = str.toString();
  return value[0].toUpperCase() + value.slice(1).toLowerCase();
};

export const getErrorMessage = (errors) => {
  const messages = errors && Object.keys(errors).map((key) => errors[key]);
  return (messages || []).join(", ");
};

export const checkIfSecureRoute = (path) => {
  const secureRoutes = [
    "/shopping-cart",
    "/checkout",
    "/order-confirm",
    "/account",
  ];
  return secureRoutes.some((r) => path.includes(r));
};

export const getSubTotal = (items) => {
  let total = 0;
  (items || []).forEach(
    (i) => (total = total + Number(i?.product_price) * Number(i?.qty))
  );
  return total.toFixed(2);
};

export const getVatTotal = (items) => {
  const total = getSubTotal(items) || 0;
  return Number((total * 0.05).toFixed(2));
};

export const refreshUserData = (updatedData) => {
  localStorage.setItem("b2c-user-data", JSON.stringify(updatedData));
};

export const isEmpty = (strValue) => {
  if (!strValue || strValue.trim() === "" || strValue.trim().length === 0) {
    return true;
  } else {
    return false;
  }
};

export const getStatus = (status) => {
  return (
    <Tag color={status ? "green" : "error"}>
      {status ? "Active" : "Inactive"}
    </Tag>
  );
};

export const getPriority = (priority) => {
  switch (priority) {
    case 0:
      return <Tag color="green">Low</Tag>;
    case 1:
      return <Tag color="blue">Medium</Tag>;
    case 2:
      return <Tag color="orange">High</Tag>;
    default:
      return <Tag color="red">Critical</Tag>;
  }
};

export const getEnvironment = (env) => {
  switch (env) {
    case 0:
      return "Development";
    case 1:
      return "Test";
    default:
      return "Production";
  }
};

export const getBugStatus = (status) => {
  switch (status) {
    case 0:
      return <Tag color="#531dab">Open</Tag>;
    case 1:
      return <Tag color="#2db7f5">In Progress</Tag>;
    case 2:
      return <Tag color="#87d068">Ready For Test</Tag>;
    case 3:
      return <Tag color="#08979c">Re Assigned</Tag>;
    default:
      return <Tag color="#cf1322">Closed</Tag>;
  }
};

export const getNameLetters = (name) => {
  const splittedName = name.split(" ");
  const firstName = splittedName[0];
  const lastName = splittedName[1];
  return firstName.charAt(0) + lastName.charAt(0);
};

export const getPriorityClassname = (priority) => {
  switch (priority) {
    case 0:
      return "green";
    case 1:
      return "blue";
    case 2:
      return "orange";
    default:
      return "red";
  }
};
