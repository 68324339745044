import React from "react";
import { Typography, Modal, Button } from "antd";
import OtpInput from "react-otp-input";

import Spin from "../../common/spinner";

import "./styles.scss";

const { Title, Paragraph } = Typography;

const EmailVerificationModal = ({
  showModal,
  closeModal,
  resendVerificationCode,
  verificationCode,
  setVerificationCode,
  accountVerify,
  loading,
}) => {
  const handleOtpChange = (o) => {
    setVerificationCode(o);
  };

  return (
    <Modal
      visible={showModal}
      onCancel={closeModal}
      destroyOnClose
      footer={null}
      className="bugify-email-verification"
    >
      {loading && <Spin title="Verifying code..." />}
      <Title level={4} style={{ marginTop: "10px" }}>
        You are almost done!
      </Title>
      <div className="verification-inputs-render">
        <Paragraph>
          A code has been sent to your Email, please check and enter here to
          verify yourself.
        </Paragraph>
        <OtpInput
          value={verificationCode}
          onChange={handleOtpChange}
          numInputs={6}
          containerStyle="verification-code-container"
          inputStyle="one-digit-input"
        />
        <Paragraph style={{ marginTop: "10px" }}>
          Don't get yet?{" "}
          <span
            style={{ color: "#ff9601", cursor: "pointer" }}
            onClick={resendVerificationCode}
          >
            Send again
          </span>
        </Paragraph>
      </div>

      <Button onClick={accountVerify} type="primary" block>
        Submit
      </Button>
    </Modal>
  );
};

export default EmailVerificationModal;
