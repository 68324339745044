import Auth from "./Auth";
import Projects from "./Projects";
import Clients from "./Clients";
import Subscriptions from "./Subscriptions";
import Settings from "./Settings";
import Member from "./Members";
import Staff from "./Staff";

const API = {
  Auth,
  Projects,
  Clients,
  Subscriptions,
  Settings,
  Member,
  Staff,
};

export default API;
