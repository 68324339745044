import axios from "axios";
import { API_URL } from "../constants";

export default class Staff {
  token = (JSON.parse(localStorage.getItem("bugify-user-data")) || {}).token;
  getStaff = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/auth/user/?limit=${payload?.limit || 10}&offset=${
          payload?.offset || 0
        }&is_staff=true&email=${payload?.email || ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  getStaffDetails = (id) => {
    return axios
      .get(`${API_URL}api/v1/auth/user/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  addStaff = (payload) => {
    return axios
      .post(
        `${API_URL}api/v1/auth/user/`,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  editStaff = (payload, id) => {
    return axios
      .put(
        `${API_URL}api/v1/auth/user/${id}/`,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  deleteStaff = (id) => {
    return axios
      .delete(`${API_URL}api/v1/auth/user/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };
}
