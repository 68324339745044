/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Form, Select, Input, Card, Button, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import EmailVerificationModal from "../email-verification";
import AccountCreated from "../account-created";
import ForgotPasswordModal from "../forgot-password";
import Spinner from "../../common/spinner";

import API from "../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../utils/helper.functions";
import { setUserData } from "../../../utils/redux/actions";

import "./styles.scss";

const { Option } = Select;

const AuthComponent = () => {
  const auth = new API.Auth();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [mode, setMode] = useState("login");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [personData, setPersonData] = useState({});
  const [verificationCode, setVerificationCode] = useState();
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showAccountCreatedModal, setShowAccountCreatedModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  useEffect(() => {
    if (mode === "signup" && countries.length === 0) {
      fetchCountries();
    }
  }, [mode]);

  const fetchCountries = async () => {
    return getDataManager(auth?.getCountry, setLoading).then((x) => {
      if (x?.status === "success") {
        setCountries(x?.data);
      } else {
        const error = getErrorMessage(x?.errors) || x?.message;
        message.error({
          content: error || "Error ocurred",
          duration: 3,
        });
      }
    });
  };

  const resendVerificationCode = () => {
    const payload = {
      email: personData?.email || email,
    };

    getDataManager(auth?.resendVerificationCode, null, payload).then((x) => {
      if (x?.status === "success") {
        message.success({
          content: x?.message || "Verification code has been sent",
          duration: 3,
        });
      } else {
        message.error({
          content:
            x?.message[0] ||
            "Error ocurred while sending the verification code",
          duration: 3,
        });
      }
    });
  };

  const accountVerify = () => {
    const payload = {
      email: personData?.email,
      code: verificationCode,
    };
    getDataManager(auth?.accountVerify, setLoading, payload).then((x) => {
      if (x?.status === "success") {
        setVerificationCode();
        setShowVerificationModal(false);
        setShowAccountCreatedModal(true);
      } else {
        const error = getErrorMessage(x?.errors);
        message.error({
          content: error || "Code verification failed",
          duration: 3,
        });
      }
    });
  };

  const onSignUp = (values) => {
    const passwordIsSame = values?.password === values?.confirm_password;
    if (passwordIsSame) {
      getDataManager(auth?.signUpUser, setLoading, values).then((x) => {
        if (x?.status === "success") {
          setShowVerificationModal(true);
          setPersonData(x?.data);
        } else {
          const error = getErrorMessage(x?.errors);
          message.error({
            content: error || "Error ocurred in signing up",
            duration: 3,
          });
        }
      });
    } else {
      message.error({ content: "Password does not match!", duration: 3 });
    }
  };

  const onLogin = (payload) => {
    getDataManager(auth?.loginUser, setLoading, payload).then((x) => {
      if (x?.status === "success") {
        if (x?.data?.token) {
          message.success({
            content: "You have successfully logged in",
            duration: 3,
          });
          localStorage.setItem("bugify-user-data", JSON.stringify(x?.data));
          dispatch(setUserData(x?.data));
          navigate("/projects");
          // handleRefresh();
        } else {
          if (x?.data?.show_popup) {
            message.info({
              content: "Please reset your password to login",
              duration: 3,
            });
          } else {
            message.warning({
              content:
                "Your account is not verified! Please verify it to proceed.",
            });
            setPersonData({ ...personData, email: x?.data?.email });
            resendVerificationCode();
            setShowVerificationModal(true);
          }
        }
      } else {
        const error = getErrorMessage(x?.errors);
        dispatch(setUserData({}));
        localStorage.removeItem("bugify-user-data");
        message.error({
          content: x?.message || error || "Error ocurred while logging in",
          duration: 3,
        });
      }
    });
  };

  const onCloseAccountCreateModal = () => {
    setShowAccountCreatedModal(false);
    setMode("login");
    message.success({
      content: "Your account has been verified, please login",
      duration: 3,
    });
  };

  const toggleMode = () => {
    var newMode = mode === "login" ? "signup" : "login";
    setMode(newMode);
  };

  const forgetPassword = (payload) => {
    getDataManager(auth?.forgetPassword, null, payload).then((x) => {
      if (x?.status === "success") {
        message.success({
          content: "OTP has been sent to your email",
          duration: 3,
          key: "loader",
        });
        setShowForgotPasswordModal(true);
      } else {
        const error = getErrorMessage(x?.errors);
        message.error({
          content: x?.message || error || "Error ocurred while sending OTP",
          duration: 3,
          key: "loader",
        });
      }
    });
  };

  const handleForgotPassword = () => {
    if (email) {
      forgetPassword({ email });
    } else {
      message.warning({
        content: "Please enter email to reset password",
        duration: 3,
      });
    }
  };

  const onCloseForgotPasswordModal = () => {
    setShowForgotPasswordModal(false);
    setVerificationCode();
    setEmail("");
  };

  return (
    <div className={`app app--is-${mode}`}>
      <div className="auth-container">
        <div
          className={`form-block-wrapper form-block-wrapper--is-${mode}`}
        ></div>
        <Card className={`form-block form-block--is-${mode}`}>
          {loading && <Spinner />}
          <header className="form-block__header">
            <h1>{mode === "login" ? "Welcome back!" : "Sign up"}</h1>
            <div className="form-block__toggle-block">
              <span>
                {mode === "login" ? "Don't" : "Already"} have an account ? Click
                here &#8594;
              </span>
              <input id="form-toggler" type="checkbox" onClick={toggleMode} />
              <label htmlFor="form-toggler"></label>
            </div>
          </header>
          <LoginForm
            mode={mode}
            onLogin={onLogin}
            onSignUp={onSignUp}
            countries={countries}
            setEmail={(e) => setEmail(e)}
            handleForgotPassword={handleForgotPassword}
          />
        </Card>
      </div>
      <EmailVerificationModal
        showModal={showVerificationModal}
        resendVerificationCode={resendVerificationCode}
        setVerificationCode={(val) => setVerificationCode(val)}
        verificationCode={verificationCode}
        accountVerify={accountVerify}
        loading={loading}
        closeModal={() => setShowVerificationModal(false)}
      />
      <AccountCreated
        showModal={showAccountCreatedModal}
        closeModal={onCloseAccountCreateModal}
      />
      <ForgotPasswordModal
        showModal={showForgotPasswordModal}
        onClose={onCloseForgotPasswordModal}
        email={email}
      />
    </div>
  );
};

const LoginForm = ({
  onLogin,
  onSignUp,
  mode,
  countries,
  setEmail,
  handleForgotPassword,
}) => {
  const [form] = Form.useForm();

  return (
    <div className="form-block__input-wrapper">
      {mode === "login" ? (
        <div className="form-group form-group--login">
          <Form form={form} onFinish={onLogin} scrollToFirstError>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please enter email",
                },
              ]}
            >
              <Input
                placeholder="Email"
                size="large"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter password",
                },
              ]}
            >
              <Input.Password
                placeholder="Password"
                visible={true}
                size="large"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" size="large" block type="primary">
                Login
              </Button>
            </Form.Item>
          </Form>
          <Button
            type="link"
            onClick={handleForgotPassword}
            style={{ padding: 0 }}
          >
            Forgot Password ?
          </Button>
        </div>
      ) : (
        <div className="form-group form-group--login">
          <Form onFinish={onSignUp}>
            <Form.Item
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please enter first name",
                },
              ]}
            >
              <Input placeholder="First name" size="large" />
            </Form.Item>
            <Form.Item
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please enter last name",
                },
              ]}
            >
              <Input placeholder="Last name" size="large" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please enter email",
                },
              ]}
            >
              <Input placeholder="Email" size="large" />
            </Form.Item>
            <Form.Item
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "Please enter mobile",
                },
              ]}
            >
              <Input placeholder="Mobile" size="large" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter password",
                },
              ]}
            >
              <Input.Password
                placeholder="Password"
                size="large"
                visible={true}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: "Please enter confirm password",
                },
              ]}
            >
              <Input.Password
                placeholder="Confirm Password"
                size="large"
                visible={true}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item
              name="country"
              rules={[
                {
                  required: true,
                  message: "Please select country",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a country"
                optionFilterProp="children"
                size="large"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {(countries || []).map((c) => (
                  <Option value={c?.id}>{c?.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button block htmlType="submit" type="primary" size="large">
                Signup
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

export default AuthComponent;
