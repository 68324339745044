import React from "react";

import { Typography, Space, Button, Modal } from "antd";

import "./styles.scss";

const { Title, Paragraph } = Typography;

const AccountCreated = ({ showModal, closeModal }) => {
  return (
    <Modal
      visible={showModal}
      onCancel={closeModal}
      destroyOnClose
      footer={null}
      className="bugify-account-created"
    >
      <Space size={10} direction="vertical">
        <Title level={4} style={{ marginTop: "10px" }}>
          Congratulations!
        </Title>
        <div className="verification-inputs-render">
          <Paragraph>Account Successfully Created!</Paragraph>
        </div>

        <Button
          onClick={() => closeModal()}
          style={{ marginTop: "10px" }}
          type="primary"
          block
        >
          Okay
        </Button>
      </Space>
    </Modal>
  );
};

export default AccountCreated;
