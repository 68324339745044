import React, { useState } from "react";
import { Input, Form, message, Typography, Button, Modal } from "antd";
import OtpInput from "react-otp-input";

import API from "../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../utils/helper.functions";

import "./styles.scss";

const { Title, Paragraph } = Typography;

const ForgotPasswordModal = ({
  showModal,
  onClose,
  email,
  resendVerificationCode,
}) => {
  const auth = new API.Auth();

  const [form] = Form.useForm();

  const [verificationCode, setVerificationCode] = useState();

  const handleOtpChange = (o) => {
    setVerificationCode(o);
  };

  const onSubmit = (values) => {
    const payload = {
      email: email,
      password: values.password,
      code: verificationCode,
    };

    if (values?.password === values?.new_password) {
      message.loading({
        content: "Updating Password",
        duration: 0,
        key: "loader",
      });
      getDataManager(auth?.forgetPasswordConfirm, null, payload).then((x) => {
        if (x?.status === "success") {
          message.success({
            content: "Password is updated successfully",
            duration: 3,
            key: "loader",
          });
          onClose();
        } else {
          const error = getErrorMessage(x?.errors);
          message.error({
            content: x?.message || error || "Error ocurred while logging in",
            duration: 3,
            key: "loader",
          });
        }
      });
    } else {
      message.warning({ content: "Password does not match", duration: 3 });
    }
  };

  const onFinish = (values) => {
    console.log({ verificationCode, len: verificationCode.length === 6 });
    if (verificationCode && verificationCode.length === 6) {
      onSubmit(values);
    } else {
      message.warning({ content: "Please enter code", duration: 3 });
    }
  };

  return (
    <>
      <Modal
        className="bugify-forgot-modal"
        visible={showModal}
        onCancel={onClose}
        destroyOnClose
        footer={null}
        preserve={false}
      >
        <div className="forgot-password-header">
          <Title level={5} style={{ margin: "20px 0" }}>
            Reset Your Passowrd
          </Title>
        </div>

        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          scrollToFirstError
        >
          <div className="verification-inputs-render">
            <Paragraph>
              A code has been sent to your Email, please check and enter here to
              verify yourself.
            </Paragraph>
            <OtpInput
              value={verificationCode}
              onChange={handleOtpChange}
              numInputs={6}
              containerStyle="verification-code-container"
              inputStyle="one-digit-input"
            />
            <Paragraph style={{ marginTop: "10px" }}>
              Don't get yet?{" "}
              <span
                style={{ color: "#ff9601", cursor: "pointer" }}
                onClick={resendVerificationCode}
              >
                Send again
              </span>
            </Paragraph>
          </div>
          <Form.Item
            name="password"
            label="New Password"
            rules={[{ required: true, message: " Please enter your passowrd" }]}
          >
            <Input placeholder="Please enter your password" />
          </Form.Item>
          <Form.Item
            name="new_password"
            label="Confirm New Password"
            rules={[{ required: true, message: " Please enter your passowrd" }]}
          >
            <Input placeholder="Please enter your confirm password" />
          </Form.Item>
          <Form.Item>
            <Button block htmlType="submit">
              Set Password
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ForgotPasswordModal;
