import axios from "axios";
import { API_URL } from "../constants";

export default class Projects {
  token = (JSON.parse(localStorage.getItem("bugify-user-data")) || {}).token;
  getProjects = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/projects/project/?limit=${
          payload?.limit || 50
        }&offset=${payload?.offset || 0}&name=${payload?.name || ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  getProjectDetails = (id) => {
    return axios
      .get(`${API_URL}api/v1/projects/project/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  addProject = (payload) => {
    return axios
      .post(
        `${API_URL}api/v1/projects/project/`,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  editProject = (payload, id) => {
    return axios
      .put(
        `${API_URL}api/v1/projects/project/${id}/`,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  deleteProject = (id) => {
    return axios
      .delete(`${API_URL}api/v1/projects/project/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  getSecretKey = (id) => {
    return axios
      .get(`${API_URL}api/v1/projects/project/${id}/regenerate_secret_key/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  getDiscussion = () => {
    return axios
      .get(`${API_URL}api/v1/projects/discussion/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  getDiscussionDetails = (id) => {
    return axios
      .get(`${API_URL}api/v1/projects/discussion/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  addDiscussion = (payload) => {
    return axios
      .post(
        `${API_URL}api/v1/projects/discussion/`,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  editDiscussion = (payload, id) => {
    return axios
      .put(
        `${API_URL}api/v1/projects/discussion/${id}/`,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  deleteDiscussion = (id) => {
    return axios
      .delete(`${API_URL}api/v1/projects/discussion/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  getBugs = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/projects/bug/?limit=${payload?.limit || 50}&offset=${
          payload?.offset || 0
        }&name=${payload?.name || ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  getBugDetails = (id) => {
    return axios
      .get(`${API_URL}api/v1/projects/bug/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  addBug = (payload) => {
    return axios
      .post(
        `${API_URL}api/v1/projects/bug/`,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  editBug = (payload, id) => {
    return axios
      .put(
        `${API_URL}api/v1/projects/bug/${id}/`,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  deleteBug = (id) => {
    return axios
      .delete(`${API_URL}api/v1/projects/bug/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  closeBug = (id) => {
    return axios
      .get(`${API_URL}api/v1/projects/bug/${id}/close/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  reAssignBug = (id) => {
    return axios
      .get(`${API_URL}api/v1/projects/bug/${id}/re_assign/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  readyForTestBug = (id) => {
    return axios
      .get(`${API_URL}api/v1/projects/bug/${id}/ready_for_test/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  assignDeveloper = (id) => {
    return axios
      .post(`${API_URL}api/v1/projects/bug/${id}/assign_developer/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  changeBugPriority = (id) => {
    return axios
      .post(`${API_URL}api/v1/projects/bug/${id}/change_priority/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  reportBug = (payload) => {
    return axios
      .post(
        `${API_URL}api/v1/projects/bug/`,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };
}
