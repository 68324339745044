/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Suspense } from "react";
import { Layout, Space, Avatar, Popover } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BellFilled,
  LoginOutlined,
} from "@ant-design/icons";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "../common/spinner";
import SiderBar from "./sidebar";

import { setUserData } from "../../utils/redux/actions";

import "./styles.scss";

const Projects = React.lazy(() => import("../projects/projects-list"));
const AddProject = React.lazy(() => import("../projects/add-project"));
const Bugs = React.lazy(() => import("../all-bugs/bugs"));
const BugsList = React.lazy(() => import("../bugs/bugs-list"));
const AddBugs = React.lazy(() => import("../bugs/add-bug"));
const Packages = React.lazy(() => import("../packages/packages-list"));
const AddPackage = React.lazy(() => import("../packages/add-package"));
const Clients = React.lazy(() => import("../clients/clients-list"));
const AddClient = React.lazy(() => import("../clients/add-client"));
const Staff = React.lazy(() => import("../staff/staff-list"));
const AddStaff = React.lazy(() => import("../staff/add-staff"));
const GlobalSettings = React.lazy(() => import("../settings/global-settings"));
const Profile = React.lazy(() => import("../profile"));
const Transactions = React.lazy(() => import("../transactions"));
const Subscriptions = React.lazy(() =>
  import("../subscriptions/subscription-list")
);
const AddSubscription = React.lazy(() =>
  import("../subscriptions/add-subscription")
);

const { Header, Content } = Layout;

const BugifyLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => {
    return state?.app;
  });

  console.log({ userData });

  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const userPopover = () => {
    return (
      <section className="user-inner-container">
        <div className="profile-opt" onClick={handleProfileClick}>
          <Avatar size={20} src="https://joeschmoe.io/api/v1/random" />
          Profile
        </div>
        <div className="logout-opt" onClick={handleLogout}>
          <LoginOutlined />
          Logout
        </div>
      </section>
    );
  };

  const handleProfileClick = () => {
    if (userData?.is_staff) {
      navigate(`/staff-profile/${userData?.id}`);
    } else if (userData?.is_client) {
      navigate(`/staff-profile/${userData?.id}`);
    } else {
      navigate(`/super-user-profile/${userData?.id}`);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("bugify-user-data");
    dispatch(setUserData({}));
    navigate("/login");
  };

  return (
    <Layout>
      <SiderBar toggle={toggle} collapsed={collapsed} />
      <Layout className="site-layout">
        <Header className="site-layout-background bugify-navbar">
          {collapsed ? (
            <MenuUnfoldOutlined onClick={toggle} className="trigger" />
          ) : (
            <MenuFoldOutlined onClick={toggle} className="trigger" />
          )}
          <Space align="center" size={20}>
            <BellFilled className="bell-icon" />
            <Popover
              placement="rightBottom"
              content={userPopover()}
              trigger="click"
              className="user-popover"
            >
              <Avatar src="https://joeschmoe.io/api/v1/random" />
            </Popover>
          </Space>
        </Header>
        <Content className="site-layout-background content-main-section">
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route path="/staff-profile/:id" element={<Profile />} />
              <Route path="/client-profile/:id" element={<Profile />} />
              <Route path="/super-user-profile/:id" element={<Profile />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/add-project" element={<AddProject />} />
              <Route path="/edit-project/:id" element={<AddProject />} />
              <Route path="/all-bugs" element={<Bugs />} />
              <Route path="/bugs" element={<BugsList />} />
              <Route path="/add-bug" element={<AddBugs />} />
              <Route path="/edit-bug/:id" element={<AddBugs />} />
              <Route path="/subscriptions" element={<Subscriptions />} />
              <Route path="/add-subscription" element={<AddSubscription />} />
              <Route
                path="/edit-subscription/:id"
                element={<AddSubscription />}
              />
              <Route path="/packages" element={<Packages />} />
              <Route path="/add-package" element={<AddPackage />} />
              <Route path="/edit-package/:id" element={<AddPackage />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/add-client" element={<AddClient />} />
              <Route path="/edit-client/:id" element={<AddClient />} />
              <Route path="/staff" element={<Staff />} />
              <Route path="/add-staff" element={<AddStaff />} />
              <Route path="/edit-staff/:id" element={<AddStaff />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/settings" element={<GlobalSettings />} />
            </Routes>
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default BugifyLayout;
