import axios from "axios";
import { API_URL } from "../constants";

export default class Subscriptions {
  token = (JSON.parse(localStorage.getItem("bugify-user-data")) || {}).token;

  getSubscriptions = () => {
    return axios
      .get(`${API_URL}api/v1/subscriptions/subscription/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  getSubscriptionDetails = (id) => {
    return axios
      .get(`${API_URL}api/v1/subscriptions/subscription/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  addSubscription = (payload) => {
    return axios
      .post(
        `${API_URL}api/v1/subscriptions/subscription/`,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  editSubscription = (payload, id) => {
    return axios
      .put(
        `${API_URL}api/v1/subscriptions/subscription/${id}/`,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  deleteSubscription = (id) => {
    return axios
      .get(`${API_URL}api/v1/subscriptions/subscription/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  getPackages = () => {
    return axios
      .get(`${API_URL}api/v1/subscriptions/package/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  getPackageDetails = (id) => {
    return axios
      .get(`${API_URL}api/v1/subscriptions/package/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  addPackage = (payload) => {
    return axios
      .post(
        `${API_URL}api/v1/subscriptions/package/`,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  editPackage = (payload, id) => {
    return axios
      .put(
        `${API_URL}api/v1/subscriptions/package/${id}/`,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  deletePackage = (id) => {
    return axios
      .get(`${API_URL}api/v1/subscriptions/package/${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${this.token}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };

  buyPackage = (payload) => {
    return axios
      .post(
        `${API_URL}api/v1/subscriptions/subscription/subscribe/`,
        { ...payload },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${this.token}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        return error.response?.data;
      });
  };
}
